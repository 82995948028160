import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function Services(props) {
    const cards = [
        {
            name: "НКУ общепромышленного назначения",
            description: "ВРУ, ГРЩ, ППУ, ПР, ПУ, РУНН, РУСМ, СКС, УКРМ, ША, ШРС, ШУВ, ШУД, ШУЗ, ШУН, ШУС, Щ, ЩАВР, ЩАО, ЩАП, ЩАУ, ЩД, ЩК, ЩО, ЩО-70, ЩПТ, ЩР, ЩРО, ЩС, ЩСН, ЩУ, ЩУР, ЩУС, ЩЭ, Я5000, ЯРП, ЯТП, ЯУ, ЯУО и т.д.",
            img: <StaticImage src={"../img/НКУ.jpg"} alt={"НКУ общепромышленного назначения"}/>
        },
        {
            name: "Высоковольтное оборудование",
            description: "КТП, КСО, ПКУ",
            img: <StaticImage src={"../img/Высоковольтное.jpg"} alt={"Высоковольтное оборудование"}/>
        },
        {
            name: "НКУ Агропромышленного комплекса",
            description: "Щиты ЩД предназначены для управления системой электродосвечивания в ручном (местном) и автоматическом (дистанционном) режимах, а также защиты цепей питания от перегрузки и короткого замыкания. Щиты монтируются внутри тепличных комплексов непосредственно на конструкциях теплиц (группа эксплуатации М1 по ГОСТ 17516-72)",
            img: <StaticImage src={"../img/Агропром.jpg"} alt={"НКУ Агропромышленного комплекса"}/>
        },
        {
            name: "Шкафы для электродвигателей, насосов, вентиляторов.",
            description: "ШУ вентиляцией (fun), ШУ универсальные (control), ШУ приводами (drive), ШУ насосами (pump), ШУ теплопунктами (heat), ШУ дозированием (dozer), ШУ задвижками и клапанами (valve), Шкафы распределительные (base), Шкаф розеточный (store)",
            img: <StaticImage src={"../img/Вентеляция.jpg"}
                              alt={"Шкафы для электродвигателей, насосов, вентиляторов."}/>
        },
        {
            name: "OEM-сборка",
            description: "Разработка и/или сборка НКУ на постоянной основе под конкретного Заказчика",
            img: <StaticImage src={"../img/Сборка.jpg"}
                              alt={"Разработка и/или сборка НКУ на постоянной основе под конкретного Заказчика"}/>
        },
        {
            name: "Доставка и пусконаладка",
            description: "Наладка оборудования нашими инжинерами",
            img: <StaticImage src={"../img/Пусконаладка.jpg"} alt={"Наладка оборудования нашими инжинерами"}/>
        }
    ]

    const cardsEl = cards.map((el, i) => (
        <div key={i}>
            <h5>{el.name}</h5>
            <div className="onHover">
                <p>{el.description}</p>
                <a href="#contactUs">
                    <button>Оставить заявку</button>
                </a>
            </div>
            {el.img}
        </div>
    ))
    return (
        <section className="Services" id="services">
            <div className="container">
                <h2>Наши услуги</h2>
                <div className="cards">
                    {cardsEl}
                </div>
            </div>
        </section>
    );
}

export default Services;